<template>
  <v-data-table :headers="headers" :items="probes" :search="search" :loading="loading">
    <template v-slot:top>
      <v-toolbar flat color="#598639" dark >
        <v-toolbar-title>{{ $t("probes") }}</v-toolbar-title>
        <v-divider class="mx-4" inset vertical></v-divider>
        <v-spacer></v-spacer>
        <v-text-field
        v-model="search"
        append-icon="mdi-magnify"
        label="Search"
        single-line
        hide-details
        clearable
      ></v-text-field>
      <v-spacer></v-spacer>
        <v-dialog v-model="dialog" max-width="600px">
          <template v-slot:activator="{ on, attrs }">
            <v-btn color="black" dark class="mb-2 " v-bind="attrs" v-on="on">
              {{ $t("Add Probe") }}
            </v-btn>
          </template>
          <v-card style="border-style: solid;  border-color: white;">
            <v-toolbar color="black">
              <v-toolbar-title >
               <v-icon large class="mr-3 mb-2" color="white">mdi-thermometer</v-icon> <span class="text-h5 white--text">{{ formTitle }}</span>
              </v-toolbar-title>
            </v-toolbar>
            <v-card-text >
              <v-form v-model="valid" ref="form">
              <v-container class="mt-3" >
                <v-row>
                  <v-col cols="12" >
                    <v-text-field
                      dense
                      v-model="editedItem.name"
                      :label="$t('Name')"
                      :counter="45"
                      :rules="rules"
                      required
                      outlined
                    ></v-text-field>
                  </v-col>
                  <v-col cols="12" >
                    <v-select
                          dense
                          v-model.number="editedItem.stn_id"
                          :label="$t('Probe type')"
                          :items="types"
                          item-text="name"
                          item-value="id"
                          persistent-hint
                          outlined
                    ></v-select>
                  </v-col>
                  <v-col cols="12" >
                    <v-row>
                      <v-col cols="3" >
                        <v-text-field
                          dense
                          outlined
                          v-model="computedHex"
                          :label="$t('HEX value')"
                          readonly
                        ></v-text-field>
                      </v-col>
                      <v-col>
                        <v-text-field
                          dense
                          outlined
                          v-model.number="editedItem.probe_num"
                          :label="$t('Address')"
                          :counter="10"
                          type="number"
                          required
                        ></v-text-field>
                      </v-col>
                    </v-row>
                  </v-col>
                  <v-col cols="12" >
                    <v-select
                          dense
                          v-model.number="editedItem.device_id"
                          :label="$t('Device')"
                          :items="devices"
                          item-text="name"
                          item-value="id"
                          persistent-hint
                          outlined
                        ></v-select>
                  </v-col>
                  <v-col cols="12">
                    <v-row>
                      <v-col cols="6">
                        <v-checkbox
                          v-model="editedItem.status"
                          :label="$t('Active')"
                          color="indigo darken-3"
                          :true-value="2"
                          :false-value="0"
                          
                        ></v-checkbox>
                      </v-col>
                    </v-row>

                  </v-col>
                </v-row>
              </v-container>
              </v-form>
            </v-card-text>

            <v-card-actions>
              <v-btn color="warning" @click="close"> {{$t('Cancel')}} </v-btn>
               <v-spacer></v-spacer>
              <v-btn color="#196619" class="white--text" @click="save" :disabled="!valid" :loading="loading2"> {{$t('Save')}} </v-btn>
            </v-card-actions>
          </v-card>
        </v-dialog>
        <v-dialog v-model="dialogDelete" max-width="500px">
          <v-card>
            <v-card-title class="text-h5"
              >{{$t('Are you sure you want to delete this item?')}}</v-card-title
            >
            <v-card-actions>
              <v-spacer></v-spacer>
              <v-btn color="blue darken-1" text @click="closeDelete"
                >{{$t('Cancel')}}</v-btn
              >
              <v-btn color="blue darken-1" text @click="deleteItemConfirm" :loading="loading2"
                >{{$t('OK')}}</v-btn
              >
              <v-spacer></v-spacer>
            </v-card-actions>
          </v-card>
        </v-dialog>
      </v-toolbar>
    </template>
    <template v-slot:item.device_id="{ item }">
        <span> {{ getDevice(item.device_id) }}</span> <span class="text-caption">(id:{{item.device_id}})</span>
    </template>
    <template v-slot:item.stn_id="{ item }">
        <span> {{ getType(item.stn_id) }}</span>
    </template>
    <template v-slot:item.status="{ item }">
        <v-icon v-if="item.status === 2"   color="green"> mdi-check-bold </v-icon>
        <v-icon v-else  color="red"> mdi-cancel</v-icon>
    </template>
    <template v-slot:item.actions="{ item }">
      <v-icon  color="blue" class="mr-2" @click="editItem(item)"> mdi-pencil </v-icon>
      <v-icon  color="red" @click="deleteItem(item)"> mdi-delete </v-icon>
    </template>
    <template v-slot:no-data>
      <v-btn color="primary" @click="initialize"> Reset </v-btn>
    </template>
  </v-data-table>
</template>

<script>
export default {
  name: "probes" ,

  data() {
    return {
      dialog: false,
      dialogDelete: false,
      probes: [],
      devices: [],
      types: [],
      valid: false,
      loading: false,
      loading2: false,
      rules: [
        v => v !== ''||  this.$t('Item is required'),
        v => v.length <= 45 || this.$t('A maximum  characters exceeded')
      ],
      editedItem: {
        id: null,
        name: '',
        status: null,
        type: '',
        probe_num: null,
        device_id: null,
        stn_id: null,
      
      },
      defaultItem: {
        id: null,
        name: '',
        status: null,
        type: '',
        probe_num: null,
        device_id: null,
        stn_id: null,
      },
      editedIndex: -1,
      search: '',
      headers: [
        {
          text: this.$t('ID'),
          align: 'start',
          sortable: true,
          value: 'id',
        },
        {
          text: this.$t('Probe Name'),
          align: 'center',
          sortable: true,
          value: 'name',
        },
        {
          text: this.$t('Type'),
          align: 'center',
          sortable: true,
          value: 'stn_id',
        },
        {
          text: this.$t('Device'),
          align: 'center',
          sortable: true,
          value: 'device_id',
        },
        {
          text: this.$t('Address'),
          align: 'center',
          sortable: true,
          value: 'probe_num',
        },
        {
          text: this.$t('Active'),
          align: 'center',
          sortable: true,
          value: 'status',
        },
        {
          text: this.$t('Actions'),
          align: 'center',
          value: 'actions',
        },
      
      
      ]
        
    }
  },
  methods: {
      async initialize () {
       try {
          this.loading = true
          const prb = await this.$http.get("/probe")
          this.probes = prb.data
          const devs = await this.$http.get("/device/probes")
          this.devices = devs.data
          const types = await this.$http.get("/gaugetypes")
          this.types = types.data
        } catch (e) {
          console.log(e)
          this.loading = false
        } finally {
          this.loading = false
        }
    },
    
    editItem (item) {
      this.editedIndex = this.probes.indexOf(item)
      this.editedItem = Object.assign({}, item)
      this.dialog = true
    },

    deleteItem (item) {
      this.editedIndex = this.probes.indexOf(item)
      this.editedItem = Object.assign({}, item)
      this.dialogDelete = true
    },

    async deleteItemConfirm () {
      let res = null
      try {
        res = await this.$http.delete(`/probe/${this.editedItem.id}`)
        this.probes.splice(this.editedIndex, 1)
        this.closeDelete()
      } catch (e) {
        this.$emit('alertMsg', { type: 'error', text: e.message +"\n" + res?.error})
        this.closeDelete()
      }
    },

    close () {
      this.dialog = false
      this.$nextTick(() => {
        this.editedItem = Object.assign({}, this.defaultItem)
        this.editedIndex = -1
      })
    },

    closeDelete () {
      this.dialogDelete = false
      this.$nextTick(() => {
        this.editedItem = Object.assign({}, this.defaultItem)
        this.editedIndex = -1
      })
    },
    validate () {
        return this.$refs.form.validate()
    },

    async save () {
      let res = null
      if (this.editedIndex > -1) {
        try {
          this.loading2 = true
          res = await this.$http.put("/probe", this.editedItem)
          Object.assign(this.probes[this.editedIndex], this.editedItem)
          this.$emit("alertMsg", {
            type: "success",
            text: "Data saved OK",
          });
        } catch (e) {
          this.$emit('alertMsg', { type: 'error', text: e.message +"\n" + res?.error})
          this.loading2 = false
        } finally{
          this.loading2 = false
        }
      } else {
        try {
          this.loading2 = true
          await this.$http.post("/probe", this.editedItem)
          this.probes.push(this.editedItem)
          this.$emit("alertMsg", {
            type: "success",
            text: "Data saved OK",
          });
        } catch (e) {
          this.$emit('alertMsg', { type: 'error', text: e.message +"\n" + res?.error})
          this.loading2 = false
        } finally{
          this.loading2 = false
        }
      }
      this.close()
      await this.initialize()
    },
    getDevice(id) {
         return this.devices.find(v => v.id === id)?.name ?? 'N/A'
    },
    getType(id) {
      return this.types.find(v => v.id === id)?.name ?? 'N/A'
    }
  },

  computed: {
      formTitle () {
        return this.editedIndex === -1 ? this.$t('Add New Probe') : this.$t('Edit Probe')
      },
      computedHex() {
        return   parseInt(this.editedItem.probe_num).toString(16)  ||  0
      },
      
    },

    watch: {
      dialog (val) {
        val || this.close()
      },
      dialogDelete (val) {
        val || this.closeDelete()
      },
      editedItem: {
        handler() {
          this.$refs.form.validate()
        },
        deep: true
      }
    },

    created () {
      this.initialize()
    },
  
    

}
</script>

